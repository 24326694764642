import React from "react"
import { graphql } from "gatsby"
import { withPreview } from "gatsby-source-prismic"

import Layout from "../components/layout/layout"
import SEO from "../components/seo"
import PageHeader from "../components/pageHeader"
import styled from "styled-components"
import Img from "gatsby-image"

const ResortMapPage = ({ data }) => {
  const {
    prismicResortMapPage: { data: pageData, uid },
  } = data
  const { title, tagline, text } = pageData.body.find(
    slice => slice.slice_type === "header"
  ).primary

  return (
    <Layout>
      <SEO title={uid} />
      <Container>
        <PageHeader title={title} text={text} tagline={tagline} />
        <Image
          // @ts-ignore
          fluid={pageData.resort_map.fluid}
          alt={pageData.resort_map.alt}
        />
      </Container>
    </Layout>
  )
}

export default withPreview(ResortMapPage)

const Container = styled.div`
  padding-bottom: 80px;
`

const Image = styled(Img)`
  max-width: 80%;
  margin: 0 auto;
`

export const query = graphql`
  query {
    prismicResortMapPage {
      uid
      data {
        body {
          ... on PrismicResortMapPageBodyHeader {
            id
            slice_type
            primary {
              title {
                html
              }
              text {
                text
              }
              tagline {
                text
              }
            }
          }
          ... on PrismicResortMapPageBodyBook {
            id
            slice_type
            primary {
              button_text
              link {
                url
                link_type
              }
              text {
                text
              }
            }
          }
        }
        resort_map {
          alt
          fluid {
            aspectRatio
            sizes
            src
            srcSet
          }
        }
      }
    }
  }
`
